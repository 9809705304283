// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/commoncomponents/Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11111;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 620px;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto; 
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.modal-body {
  margin-top: 20px;
  overflow-y: auto; 
  max-height: calc(100vh - 60px); 
}

 `, "",{"version":3,"sources":["webpack://./src/commoncomponents/form/GetInTouch.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,8BAA8B;AAChC","sourcesContent":["/* src/commoncomponents/Modal.css */\r\n.modal-overlay {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  right: 0;\r\n  bottom: 0;\r\n  background: rgba(0, 0, 0, 0.7);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  z-index: 11111;\r\n}\r\n\r\n.modal-content {\r\n  background: white;\r\n  padding: 20px;\r\n  border-radius: 8px;\r\n  width: 620px;\r\n  max-height: 100vh;\r\n  display: flex;\r\n  flex-direction: column;\r\n  position: relative;\r\n  overflow-y: auto; \r\n}\r\n\r\n.close-button {\r\n  position: absolute;\r\n  top: 10px;\r\n  right: 10px;\r\n  background: none;\r\n  border: none;\r\n  font-size: 1.5rem;\r\n  cursor: pointer;\r\n}\r\n\r\n.modal-body {\r\n  margin-top: 20px;\r\n  overflow-y: auto; \r\n  max-height: calc(100vh - 60px); \r\n}\r\n\r\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
