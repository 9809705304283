import React, { useState } from 'react';
import './GetInTouch.css';
import { toast } from 'react-toastify';

const GetInTouch = ({ show, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    segment_of_intrest: '',
    query: '',
    organization: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    try {
      const response = await fetch(`${apiBaseUrl}/getintouch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();
      if (response.ok) {
        console.log('API Response:', result);
        toast.success('Form submitted successfully!');
        setFormData({
          name: '',
          email: '',
          phone_number: '',
          segment_of_intrest: '',
          query: '',
          organization: '',
        });
        onClose();
      } else {
        console.error('API Error Response:', result);
        toast.error('Error submitting form: ' + result.message);
      }
    } catch (error) {
      console.error('Network Error:', error);
      toast.error('Error submitting form. Please try again.');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <div className="modal-body">
          <h3>Contact information</h3>
          <p>Be a part of Ideas to Impacts Hub's ever-growing Startup Ecosystem!
            Let us know how we can assist and collaborate for the brighter tomorrow</p><hr />
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="name@example.com"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Contact Number</label>
              <input
                type="number"
                className="form-control"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Enter contact number"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Segment of Your Interest</label>
              <input
                type="text"
                className="form-control"
                name="segment_of_intrest"
                value={formData.segment_of_intrest}
                onChange={handleChange}
                placeholder="Enter here"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Your Queries</label>
              <input
                type="text"
                className="form-control"
                name="query"
                value={formData.query}
                onChange={handleChange}
                placeholder="Enter your queries"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Organization</label>
              <input
                type="text"
                className="form-control"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                placeholder="Enter your organization"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
