import React, { useState } from 'react'
import Heading from '../../commoncomponents/Heading/Heading';
import './Corporate.css'

const Benefit = () => {
  const Benefits = [
    {
      name: '50% Cost Effective',
      description: [
        'Research and development',
        'Product design with cutting-edge solutions',
        'High-quality talent hiring'
      ],
    },
    {
      name: '30% Faster',
      description: [
        'Market Penetration',
        'Development of new products and services',
        'Generation of additional revenue streams'
      ],
    },
    {
      name: '80% Financial Returns on investment',
      description: [
        'Equity ownership',
        'Acquisition',
        'Other forms of exit'
      ],
    },
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="container py-5">
      <Heading title="Cost Benefit Ratio" />
      <p className='Benefit-subtitle'>Numbers Speak Louder</p>
      <div className="accordion1 py-5">
        {Benefits.map((Benefit, index) => (
          <div key={index} className="accordion-item1">
            <div className="accordion-header1" onClick={() => toggleAccordion(index)}>
              <h2 className='Benefit-name'>{Benefit.name}</h2>
              <div className="arrow-img"></div>
            </div>
            {activeIndex === index && (
              <div className="accordion-content1">
                <ul>
                  {Benefit && Benefit.description.map((item, i) => (
                    <li key={i} className='benifit-list'>{item}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
export default Benefit;