import React from 'react'
import logoImg from '../../assets/images/main-logo.svg';
import { Link, useNavigate } from 'react-router-dom';
const DashboardNav = ({showAllEvent}) => {

  return (
    <div className='container-fluid bg-dashborad'>
      <div className='d-flex justify-content-between align-items-center'>
      <Link to='/dashboard'> <img src={logoImg} alt='logo' className='img-fluid' /> </Link>
        <div>
        {showAllEvent ? (
              <Link className='btn btn-warning' to='/dashboard'> 
              All Event
            </Link>
            ) : (
              <Link className='btn btn-warning' to='/addevent'> 
              Add Event
            </Link>
            )}
          <Link className='btn btn-success ms-3' to='/ViewOtherRegistration'> View Other Registration</Link>
        </div>
        
      </div>
    
  </div>
  )
}
export default DashboardNav;