import React, {useState} from 'react'
import GetinTouch from '../form/GetinTouch';

 function GetInTouch({title}) {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
      setShowModal(true);
    };
    
    const handleCloseModal = () => {
      setShowModal(false);
    };
  return (
   <>
     <button 
     onClick={handleOpenModal}
          style={{
            backgroundColor:'#F59E0B',
            border:'none',
            padding:'8px',
            borderRadius:'25px',
            fontWeight:'bold',
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            width:'140px'
        }}
    >
        {title}
    </button>
    <GetinTouch  show={showModal} onClick={handleOpenModal} onClose={handleCloseModal}/>
   </>
  )
}
export default GetInTouch;