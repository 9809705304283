import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify'; 
import { Link, useNavigate } from 'react-router-dom';

const AddEvent = () => {
  const fileInputRef = useRef(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    event_title: '',
    event_date: '',
    event_time: '',
    event_endTime: '',
    event_description: '',
    event_location: '',
    event_image: null, // Set initial value to null
  });
  const [isFormValid, setIsFormValid] = useState(false);
  const [error, setError] = useState('');
  const [preview, setPreview] = useState('');

  // Get the current date in YYYY-MM-DD format
  const today = new Date().toISOString().split('T')[0];

  const handleFileChange = () => {
    const file = fileInputRef.current.files[0];
    if (file) {
      setFormData((prevData) => ({ ...prevData, event_image: file }));

      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        setPreview('');
      }
    } else {
      setFormData((prevData) => ({ ...prevData, event_image: null }));
      setPreview('');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const eventData = new FormData();
    eventData.append('event_title', formData.event_title);
    eventData.append('event_date', formData.event_date);
    eventData.append('event_time', formData.event_time);
    eventData.append('event_endTime', formData.event_endTime);
    eventData.append('event_location', formData.event_location);
    eventData.append('event_description', formData.event_description);
    if (formData.event_image) {
      eventData.append('event_image', formData.event_image);
    }

    try {
      const response = await fetch(`${apiBaseUrl}/event`, {
        method: 'POST',
        body: eventData,
      });

      const data = await response.json();
      toast.success(data.message);
      setTimeout(() => {
        navigate('/dashboard')
      }, 1500);
    } catch (error) {
      setError('An error occurred. Please try again.');
      toast.error('An error occurred. Please try again.');
    }
  };

  useEffect(() => {
    const isValid = Object.values(formData).every((value) => value !== '' && value !== null);
    setIsFormValid(isValid);
  }, [formData]);

  return (
    <div className='container'>
      <div className='event-form w-50 mx-auto shadow py-5 p-5 mt-5'>
        <h3>Add New Event</h3>
        <form onSubmit={handleSubmit}>
          <div className='mb-3'>
            <label>Add Title</label>
            <input
              type="text"
              className="form-control mt-2"
              name="event_title"
              placeholder="Enter Title of Event"
              value={formData.event_title}
              onChange={handleChange}
            />
          </div>
          <div className='mb-3'>
            <label>Add Date</label>
            <input
              type="date"
              className="form-control mt-2"
              name="event_date"
              value={formData.event_date}
              onChange={handleChange}
              min={today} // Disable past dates
            />
          </div>
          <div className='mb-3'>
            <label>Start Time</label>
            <input
              type="time"
              className="form-control mt-2"
              name="event_time"
              value={formData.event_time}
              onChange={handleChange}
            />
          </div>
          <div className='mb-3'>
            <label>End Time</label>
            <input
              type="time"
              className="form-control mt-2"
              name="event_endTime"
              value={formData.event_endTime}
              onChange={handleChange}
            />
          </div>
          <div className='mb-3'>
            <label>Event Description</label>
            <div className="form-floating mt-2">
              <textarea
                className="form-control"
                name="event_description"
                style={{ height: "100px" }}
                value={formData.event_description}
                onChange={handleChange}
              ></textarea>
              <label htmlFor="floatingTextarea2">Enter Event Description</label>
            </div>
          </div>
          <div className='mb-3'>
            <label>Add Location</label>
            <input
              type="text"
              className="form-control mt-2"
              name="event_location"
              placeholder='Enter Location'
              value={formData.event_location}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Add Image</label>
            <input
                className='ms-3'
              type="file"
              accept="image/*, .pdf, .doc, .docx, .txt"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          {preview && (
            <div className='mb-3'>
              <h4>Image Preview:</h4>
              <img src={preview} alt="Preview" style={{ maxWidth: '100%', height:'200px' }} />
            </div>
          )}
          <div className="modal-footer">
            <Link type="button" className="btn btn-secondary" to="/dashboard">Close</Link>
            <button type="submit" className="btn btn-primary" disabled={!isFormValid}>Save Event</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEvent;
