import React, { useState } from "react";
import enterpriseImg1 from "../../assets/images/corporate/garden/garden-1.svg";
import enterpriseImg2 from "../../assets/images/corporate/garden/garden-2.svg";
import enterpriseImg3 from "../../assets/images/corporate/garden/garden-3.svg";
import "./Corporate.css";
import Engagementform from "./Engagementform";
const EnterpriseGarden = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="container py-5">
        <div>
          <h1 className="enterprise-heading">Enterprise Garden</h1>
          <p className="enterprise-text">
            Maximize your organization's ecosystem with a suite of cloud-based
            intelligent digital applications.
          </p>
          <p className="enterprise-text">
            Ideas to Impacts Hub and Primus Techsystems have come together to
            build Enterprise Garden- A revolutionary platform that provides
            niche solutions to corporates by integrating innovative startups
            with SAP.
          </p>
          <p className="enterprise-text">
            Partner with us for unrestricted growth -
          </p>
          <ul className="enterprise-list">
            <li>
              Experience the power of integration with SAP S/4HANA Cloud,
              on-premise, utilizing the robust capabilities of the SAP BTP
              platform.
            </li>
            <li>
              Our suite seamlessly connects business networks, people,
              processes, and systems across an organization, including
              production facilities, machines, employees, customers, suppliers,
              and partners.
            </li>
            <li>
              Enterprise Garden will provide a one-stop solution for businesses
              looking to stay ahead of the curve and competitive in the
              ever-changing business landscape.
            </li>
          </ul>
        </div>
        <div className="py-5">
          <h1 className="enterprise-heading">Here is How</h1>
          <div className="row row-cols-1 row-cols-md-3 g-4 py-5">
            <div className="col">
              <div className="enterprise-card">
                <img
                  src={enterpriseImg1}
                  alt="enterprise Img"
                  className="enterprise-img"
                />
                <div className="py-3">
                  <h3>1</h3>
                  <div className="hr-here-card"></div>
                </div>

                <p className="enterprise-text mt-2">
                  I2I Hub & Primus will reach out to Enterprises for One Cloud
                  Contracts for specific Digital Solutions/Apps
                </p>
              </div>
            </div>
            <div className="col">
              <div className="enterprise-card here-last-card">
                <img
                  src={enterpriseImg2}
                  alt="enterprise Img"
                  className="enterprise-img"
                />
                <div className="py-3">
                  <h3>2</h3>
                  <div className="hr-here-card"></div>
                </div>

                <p className="enterprise-text mt-2">
                  Primus will identify the business requirement and carry out a
                  Fit-Gap Analysis
                </p>
              </div>
            </div>
            <div className="col">
              <div className="enterprise-card here-last-card2">
                <img
                  src={enterpriseImg3}
                  alt="enterprise Img"
                  className="enterprise-img"
                />
                <div className="py-3">
                  <h3>3</h3>
                  <div className="hr-here-card"></div>
                </div>
                <p className="enterprise-text mt-2">
                  After the analysis, Primus will configure the right solution
                  for the Startup and deploy the application.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid getStarted">
        <h1 className="enterprise-heading">
          Forge an invaluable alliance with us to bring innovation to your
          corporate.
        </h1>
        <button className="btn-getStarted" onClick={handleOpenModal}>
          Get Started Now
        </button>
      </div>
      <Engagementform show={showModal} onClose={handleCloseModal} />
    </>
  );
};
export default EnterpriseGarden;
