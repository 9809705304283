import React, {useState, useEffect} from 'react'

const BookSpace = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [myData,setMyData] = useState([]);

  const getData = async () => {
    const result = await fetch(`${apiBaseUrl}/bookspace`);
    const finalResult = await result.json();
    setMyData(finalResult.data);
  }
  useEffect(() => {
    getData();
  }, []); 
  return (
    <div className='container-fluid py-5'>
    <div className='table-responsive'>
       <table className='table table-bordered'>
         <thead>
           <tr>
             <th>Name</th>
             <th>Email</th>
             <th>Phone Number</th>
             <th>Organization</th>
             <th>Preferred location</th>
             <th>No of Seats</th>
             <th>Why I2I Hub</th>
           </tr>
         </thead>
         <tbody>
           {myData && myData.map((data, index) => (
             <tr key={index}>
               <td>{data.name}</td>
               <td>{data.email}</td>
               <td>{data.phone_number}</td>
               <td>{data.organization}</td>
              <td>{data.preferred_location}</td>
              <td>{data.number_of_seats_or_cabin}</td>
              <td>{data.why_i2i_hub}</td>
             </tr>
           ))}
         </tbody>
       </table>
     </div>
</div>
  )
}
export default BookSpace;