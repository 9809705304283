// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AdminPage.css */
.admin-page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa; /* Optional: Light background color */
  }
  
  .login-form {
    width: 100%;
    max-width: 400px; /* Adjust the max width as needed */
    padding: 20px;
    background: #fff; /* Optional: White background for form */
    border-radius: 8px; /* Optional: Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Shadow for better visibility */
  }
  
  .login-form h2 {
    margin-bottom: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Style/AdminStyle.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,yBAAyB,EAAE,qCAAqC;EAClE;;EAEA;IACE,WAAW;IACX,gBAAgB,EAAE,mCAAmC;IACrD,aAAa;IACb,gBAAgB,EAAE,wCAAwC;IAC1D,kBAAkB,EAAE,8BAA8B;IAClD,uCAAuC,EAAE,2CAA2C;EACtF;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":["/* AdminPage.css */\r\n.admin-page-container {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    background-color: #f8f9fa; /* Optional: Light background color */\r\n  }\r\n  \r\n  .login-form {\r\n    width: 100%;\r\n    max-width: 400px; /* Adjust the max width as needed */\r\n    padding: 20px;\r\n    background: #fff; /* Optional: White background for form */\r\n    border-radius: 8px; /* Optional: Rounded corners */\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Shadow for better visibility */\r\n  }\r\n  \r\n  .login-form h2 {\r\n    margin-bottom: 20px;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
