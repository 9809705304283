import React, { useState } from 'react';
import { toast } from 'react-toastify';

const BeCenterform = ({ show, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    organization: '',
    brief_about_org_facility: '',
    address: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/beacenter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      
      if (response.ok) {
        const result = await response.json();
        toast.success('Form submitted successfully!');
        console.log(result);
        onClose();
      } else {
        const errorResult = await response.json();
        toast.error('Error submitting form: ' + errorResult.message);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error submitting form. Please try again.');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <h3>Turn Co-Working into Innovation Hub</h3>
            <p>We are now in the process of extending our presence across various locations in India. Through a revenue-sharing arrangement, we collaborate with real estate partners or facility owners to establish and operate the I2I Hub coworking model.</p>
            <hr />
            <div className="mb-3">
              <label className="form-label">Name <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email <span className='star'>*</span></label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="name@example.com"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Contact Number <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Enter contact number"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Organization <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                placeholder="Organization Name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Brief about your organization/ facility <span className='star'>*</span></label>
              <textarea
                className="form-control"
                name="brief_about_org_facility"
                value={formData.brief_about_org_facility}
                onChange={handleChange}
                placeholder="Brief about your organization/ facility"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Address of your facility <span className='star'>*</span></label>
              <textarea
                className="form-control"
                name="address"
                value={formData.address}
                onChange={handleChange}
                placeholder="Address of your facility"
                required
              />
            </div>
            <hr />
            <div className='mb-3'>
              <button className='btn btn-primary' type='submit'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BeCenterform;
