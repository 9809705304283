// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar{
    background-color: #FFFCF2 !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.nav-link {
    color: black !important; 
    font-size: 18px;
    font-family: "Inter Tight", sans-serif;
  }
  
  .nav-link.active {
    font-weight: bold; 
  }
  
  .nav-item {
    margin-right: 15px; 
  }
  
  .nav-item:last-child {
    margin-right: 0; 
  }
  
  .img-logo{
    height: 95px !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/Navbar/Navbar.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,2CAA2C;AAC/C;AACA;IACI,uBAAuB;IACvB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":[".navbar{\r\n    background-color: #FFFCF2 !important;\r\n    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;\r\n}\r\n.nav-link {\r\n    color: black !important; \r\n    font-size: 18px;\r\n    font-family: \"Inter Tight\", sans-serif;\r\n  }\r\n  \r\n  .nav-link.active {\r\n    font-weight: bold; \r\n  }\r\n  \r\n  .nav-item {\r\n    margin-right: 15px; \r\n  }\r\n  \r\n  .nav-item:last-child {\r\n    margin-right: 0; \r\n  }\r\n  \r\n  .img-logo{\r\n    height: 95px !important;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
