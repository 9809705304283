import React, { useState } from 'react';
import {toast} from 'react-toastify'; 

const Ourcenterform = ({ show, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    preferred_location: '',
    organization: '',
    number_of_seats_or_cabin: '',
    why_i2i_hub: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/bookspace`, {
    
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(`${apiBaseUrl}/bookspace`,)
      if (response.ok) {
        const result = await response.json();
        toast.success('Form submitted successfully!');
        console.log(result);
        onClose();
      } else {
        alert('Error submitting form. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <h3>Be a part of the Ideas to Impacts Hub family!</h3>
            <hr />
            <div className="mb-3">
              <label className="form-label">Name <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email <span className='star'>*</span></label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="name@example.com"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Contact Number <span className='star'>*</span></label>
              <input
                type="number"
                className="form-control"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Enter contact number"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Organization <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                placeholder="Organization Name"
                required
              />
            </div>
            <div className='mb-3'>
              <label className="form-label">Preferred Location to work with us<span className='star'>*</span></label>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="preferred_location" id="baner" value="Baner" onChange={handleChange} />
                <label className="form-check-label" htmlFor="baner">Baner</label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="preferred_location" id="pan_card_club_road" value="Pan Card Club Road" onChange={handleChange} />
                <label className="form-check-label" htmlFor="pan_card_club_road">Pan Card Club Road</label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="preferred_location" id="wakad" value="Wakad" onChange={handleChange} />
                <label className="form-check-label" htmlFor="wakad">Wakad</label>
              </div>
              <div className="form-check">
                <input className="form-check-input" type="radio" name="preferred_location" id="viman_nagar" value="Viman Nagar" onChange={handleChange} />
                <label className="form-check-label" htmlFor="viman_nagar">Viman Nagar</label>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Number of seats and cabins you are looking at <span className='star'>*</span></label>
              <input
                type="number"
                className="form-control"
                name="number_of_seats_or_cabin"
                value={formData.number_of_seats_or_cabin}
                onChange={handleChange}
                placeholder="Your answer"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Why do you want to be a part of the I2I Hub?<span className='star'>*</span></label>
              <textarea
                className="form-control"
                name="why_i2i_hub"
                value={formData.why_i2i_hub}
                onChange={handleChange}
                placeholder="Your answer"
                required
              />
            </div>
            <hr />
            <div className='mb-3'>
              <button className='btn btn-primary' type='submit'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Ourcenterform;
