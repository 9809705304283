import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Style/AdminStyle.css';

const AdminPage = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      const response = await fetch(`${apiBaseUrl}/auth/signin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          user_name: username,
          password: password,
        }),
      });

      const data = await response.json();

      if (response.ok && data.result && data.data !== 401) {
        localStorage.setItem('token', data.data.token);
        console.log(data.data.token);
        navigate('/dashboard');
      } else {
        setError(data.message || 'Invalid credentials, please try again.');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="admin-page-container">
      <div className="login-form">
        <h2>Login</h2>
        {error && <p className="text-danger mb-3">{error}</p>}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">Username</label>
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className={`btn btn-primary w-100 ${username && password ? '' : 'disabled'}`}
            disabled={!username || !password}
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminPage;
