// routes.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import StartupPage from './pages/StartupPage';
import CorporatePage from './pages/CorporatePage';
import FacilitySpacePage from './pages/FacilitySpacePage';
import EventsPage from './pages/EventsPage';
import LandExpandPage from './pages/LandExpandPage';
import AdminPage from './pages/AdminPage';
import { Dashboard } from './components/Admin/Dashboard';
import AddEvent from './components/Admin/AddEvent';
import EditEvent from './components/Admin/EditEvent';
import ViewOtherRegistration from './components/Admin/ViewOtherRegistration';

const RoutesConfig = () => (
  <Routes>
    <Route path="/" element={<HomePage />} />
    <Route path="/startup" element={<StartupPage />} />
    <Route path="/corporate" element={<CorporatePage />} />
    <Route path="/facility-space" element={<FacilitySpacePage />} />
    <Route path="/events" element={<EventsPage />} />
    <Route path="/land-expand" element={<LandExpandPage />} />
    <Route path="/admin" element={<AdminPage />} />
    <Route path="/dashboard" element={<Dashboard />} />
    <Route path="/addevent" element={<AddEvent />} />
    <Route path="/ViewOtherRegistration" element={<ViewOtherRegistration />} />
    <Route path="/edit-event/:id" element={<EditEvent />} />
  </Routes>
);

export default RoutesConfig;
