import React from 'react'
import './Footer.css'
import mainLogo from '../../assets/images/main-logo.svg'

const Footer = () => {
    return (
        <div className='container'>
            <div className='row py-5'>
                <div className='col-md-5'>
                    <div>
                        <img src={mainLogo} alt='main logo' className='img-fluid' />
                        <p className='footer-text'>Ideas to Impacts Hub is a tech startup incubator associated with the Startup India Initiative & known for encouraging the startup ecosystem with our range of verticals.</p>
                        <p><i class="bi bi-envelope-fill footer-text"></i> hub.ideastoimpacts.com</p>
                        <p><i class="bi bi-phone-fill footer-text"></i> +91 7028082007</p>
                        <p><i class="bi bi-phone-fill footer-text"></i> +91 7028727406</p>

                        <div>
                            <a href='https://www.linkedin.com/company/ideas-to-impacts-hub/'>
                                <i class="bi bi-linkedin"></i>
                            </a>
                            <a href='https://www.instagram.com/ideas_to_impacts_hub/'>
                                <i class="bi bi-instagram ms-2"></i>

                            </a>
                            <a href='https://chat.whatsapp.com/L3PwFGo1KQGG9bMudGLBl7'>
                                <i class="bi bi-whatsapp ms-2"></i>
                            </a>
                            <a href='https://www.youtube.com/@ideastoimpactshub'>
                                <i class="bi bi-youtube ms-2"></i>
                            </a>


                        </div>
                    </div>
                </div>
                <div className='col-md-7'>
                    <div className='row row-cols-1 row-cols-md-4 g-4 py-5'>
                        <div className='col'>
                            <h6 className='footer-text'>Startup</h6>
                            <p className='footer-text'>Incubation</p>
                            <p className='footer-text'>Membership</p>
                            <p className='footer-text'>SAP BTP Innovations</p>
                        </div>
                        <div className='col'>
                            <h6 className='footer-text'>Corporates</h6>
                            <p className='footer-text'>CFO Services</p>
                            <p className='footer-text'>Corporate Innovation</p>
                            <p className='footer-text'>SAP BTP Solutions</p>
                        </div>
                        <div className='col'>
                            <h6 className='footer-text'>Events</h6>
                            <p className='footer-text'>Our Events</p>
                            <p className='footer-text'>Our Speakers</p>
                            <p className='footer-text'>Host an Event</p>
                            <p className='footer-text'>Sponsor an Event</p>
                        </div>
                        <div className='col'>
                            <h6 className='footer-text'>Facilities</h6>
                            <p className='footer-text'>Book a space</p>
                            <p className='footer-text'>Be a Center</p>
                            <p className='footer-text'>Virtual Address</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;
