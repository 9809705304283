import React, { useState } from 'react';
import { toast } from 'react-toastify';

const Engagementform = ({ show, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    organization: '',
    designation: '',
    segment_intrest: ''
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/reachtous`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        toast.success('Form submitted successfully!');
        console.log(result);
        onClose();
      } else {
        const errorResult = await response.json();
        toast.error('Error submitting form: ' + errorResult.message);
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('Error submitting form. Please try again.');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <h3>Corporate Engagement with Ideas to Impacts Hub</h3>
            <p>
              Ideas to Impacts Hub offers corporate services including branding, talent sourcing, and business impact with collaborations. We facilitate innovation through hackathons and startup scouting, and enhance business efficiency and cash flow. Fill in the form to step towards utilizing our network.
            </p>
            <div className="mb-3">
              <label className="form-label">Name <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email <span className='star'>*</span></label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="name@example.com"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Contact Number <span className='star'>*</span></label>
              <input
                type="number"
                className="form-control"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Enter contact number"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Organization <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                placeholder="Organization Name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Designation <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                placeholder="designation"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">What segment interests you the most? <span className='star'>*</span></label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="segment_intrest" // Updated: Correct name attribute
                  value="Hackathons"
                  onChange={handleChange}
                  checked={formData.segment_intrest === "Hackathons"} // Updated: Set checked attribute
                  required
                />
                <label className="form-check-label">
                  Hackathons
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="segment_intrest" // Updated: Correct name attribute
                  value="Innovation Garage"
                  onChange={handleChange}
                  checked={formData.segment_intrest === "Innovation Garage"} // Updated: Set checked attribute
                />
                <label className="form-check-label">
                  Innovation Garage
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="segment_intrest" // Updated: Correct name attribute
                  value="Innovation Day"
                  onChange={handleChange}
                  checked={formData.segment_intrest === "Innovation Day"} // Updated: Set checked attribute
                />
                <label className="form-check-label">
                  Innovation Day
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="segment_intrest" // Updated: Correct name attribute
                  value="Startup Scouting"
                  onChange={handleChange}
                  checked={formData.segment_intrest === "Startup Scouting"} // Updated: Set checked attribute
                />
                <label className="form-check-label">
                  Startup Scouting
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="segment_intrest" // Updated: Correct name attribute
                  value="Any/ Open to discuss"
                  onChange={handleChange}
                  checked={formData.segment_intrest === "Any/ Open to discuss"} // Updated: Set checked attribute
                />
                <label className="form-check-label">
                  Any/ Open to discuss
                </label>
              </div>
            </div>
            <hr />
            <div className='mb-3'>
              <button className='btn btn-primary' type='submit'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Engagementform;
