import React, { useState, useEffect } from 'react';
import './Admin.css';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

export const ShowEvent = () => {
  const [allEvent, setAllEvent] = useState([]);

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('upcoming');
  const [allEventParticipants, setAllEventParticipants] = useState([]);
  const [countEvent, setCountEvent] = useState(0);
  const [showParticipantModal, setShowParticipantModal] = useState(false);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const getEventApi = async () => {
    try {
      const result = await fetch(`${apiBaseUrl}/event`);
      const finalEvent = await result.json();
      setAllEvent(finalEvent.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching events:', error);
      setLoading(false);
    }
  };
  const getParticipants = async (eventId) => {
    try {
      const result = await fetch(`${apiBaseUrl}/event/${eventId}/participants`);
      const participants = await result.json();
      setAllEventParticipants(participants.data);
      const participantCount = participants.data.length;
      setCountEvent(participantCount);
      setShowParticipantModal(true);
    } catch (error) {
      console.error('Error fetching participants:', error);
    }
  };
  useEffect(() => {
    getEventApi();
  }, []);

  const upcomingEvents = allEvent.filter(event => !event.isArchived);
  const completedEvents = allEvent.filter(event => event.isArchived);
  const filteredEvents = activeTab === 'upcoming' ? upcomingEvents : completedEvents;

  const deleteEvent = async (id) => {
    try {
      const response = await fetch(`${apiBaseUrl}/event/${id}`, {
        method: 'DELETE',
      });
      console.log("delete resp", response)

      if (response.ok) {
        toast.error("Event Deleted Succesfully", {
          autoClose: 3000,
        })
        setAllEvent(allEvent.filter(event => event.id !== id));
      } else {
        console.error('Failed to delete the event');
      }
    } catch (error) {
      console.error('An error occurred while deleting the event:', error);
    }
  };
  const exportToExcel = () => {
    try {
      const fileName = 'event_participants.xlsx';
      const worksheet = XLSX.utils.json_to_sheet(allEventParticipants);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Participants');
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };



  return (
    <>
      <div className='container'>
        <h2 className='adminevent-heading'>All Events</h2>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'upcoming' ? 'active' : ''}`}
            onClick={() => setActiveTab('upcoming')}
          >
            Upcoming Events
          </button>
          <button
            className={`tab ${activeTab === 'completed' ? 'active' : ''}`}
            onClick={() => setActiveTab('completed')}
          >
            Completed Events
          </button>
        </div>

        {loading ? (
          <p>Loading...</p>
        ) : (
          <div className="event-list row g-4">
            {filteredEvents.length > 0 ? (
              filteredEvents.map((event, index) => (
                <div className="col-12 col-sm-4 col-md-3 py-3" key={index}>
                  <div className='event-card'>
                    <img src={`https://s3.ideastoimpactshub.com/${event.event_image}`} alt={event.event_title} className=' event-img' />
                    <h5 className='event-card-heading mt-3'>{event.event_title}</h5>
                    <p className='event-card-text'>{event.event_description}</p>
                    <p className='event-card-text'><strong>Location:</strong> {event.event_location}</p>
                    <p className='event-card-text'><strong>Date:</strong> {event.event_date}</p>
                    <p className='event-card-text'><strong>Time:</strong> {event.event_time} - {event.event_endTime}</p>
                    {activeTab === 'upcoming' && (
                      <>
                        <button
                          className="btn btn-danger mt-3"
                          onClick={() => deleteEvent(event.id)}
                        >
                          Delete
                        </button>
                        <Link to={`/edit-event/${event.id}`}>
                          <button className="btn btn-warning mt-3 ms-3">Update</button>
                        </Link>
                      </>
                    )}
                    <div className='py-3'>
                      <button className='btn btn-primary w-100' onClick={() => getParticipants(event.id)} >Check  Participants</button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No events found.</p>
            )}
          </div>
        )}
      </div>
      {showParticipantModal && (
        <>
          <div className='custom-modal-overlay-1'>
            <div className='custom-modal-content-1'>
              <button
                className='custom-modal-close-1'
                onClick={() => setShowParticipantModal(false)}
              >
                &times;
              </button>
              <h5 className='modal-title'>All Event Participants {countEvent}</h5>
              <button onClick={exportToExcel} className='btn btn-primary mt-5'>Export to Excel</button>
              <div class="table-responsive">
              <table class="table table-bordered mt-5">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Startup</th>
                    <th scope="col">Startup Domain</th>
                    <th scope="col">Designation</th>
                  </tr>
                </thead>
                <tbody>
                  {allEventParticipants && allEventParticipants.length > 0 ? (
                    allEventParticipants.map((participant) => (
                      <tr key={participant.id}>
                        <td>{participant.participant_name}</td>
                        <td>{participant.participant_email}</td>
                        <td>{participant.participant_phone_number}</td>
                        <td>{participant.isStartup ? 'Yes' : 'No'}</td>
                        <td>{participant.isStartup ? participant.participant_startup_domain : 'N/A'}</td>
                        <td>{participant.participant_org_designation}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No participants available.</td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
