import React, {useState, useEffect} from 'react'

const LandExpand = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [myData,setMyData] = useState([]);

  const getData = async () => {
    const result = await fetch(`${apiBaseUrl}/landexpansion`);
    const finalResult = await result.json();
    setMyData(finalResult.data);
  }
  useEffect(() => {
    getData();
  }, []); 
  return (
    <div className='container-fluid py-5'>
    <div className='table-responsive'>
       <table className='table table-bordered'>
         <thead>
           <tr>
             <th>Name</th>
             <th>Email</th>
             <th>Phone Number</th>
             <th>Organization</th>
             <th>LinkedIn link</th>
             <th>Company website</th>
             <th>Country name</th>
             <th>Rationale Behind Expansion</th>
           </tr>
         </thead>
         <tbody>
           {myData && myData.map((data, index) => (
             <tr key={index}>
               <td>{data.name}</td>
               <td>{data.email}</td>
               <td>{data.phone_number}</td>
               <td>{data.organization}</td>
              <td>{data.linkedIn_link}</td>
              <td>{data.company_website}</td>
              <td>{data.country_name_to_expand}</td>
              <td>{data.rationale_behind_expansion}</td>
             </tr>
           ))}
         </tbody>
       </table>
     </div>
</div>
  )
}
export default LandExpand;