import React from 'react'
import Heading from '../../commoncomponents/Heading/Heading'
import productImg1 from '../../assets/images/events-page/products/icon-1.svg'
import productImg2 from '../../assets/images/events-page/products/icon-2.svg'
import productImg3 from '../../assets/images/events-page/products/icon-3.svg'
import productImg4 from '../../assets/images/events-page/products/icon-4.svg'
import productImg5 from '../../assets/images/events-page/products/icon-5.svg'
import './Events.css'

const Products = () => {
  return (
    <div className='container-fluid'>
        <div className='text-center'>
            <Heading title="Products" />
            <p className="product-text-1 mt-3">Many ways of growing with Ideas to Impacts Hub</p>
        </div>
        <div className='row row-cols-1 row-cols-md-5 mx-auto g-4 py-5'>
            <div className='col text-center'>
                <img src={productImg1} alt='product img' className='product-img-1'/>
                <h3 className="prod-head">Demo Days</h3>
                <p className="prod-head-text">Startup pitches in front of stakeholders</p>
            </div>
            <div className='col text-center'>
                <img src={productImg2} alt='product img' className='product-img-2'/>
                <h3 className="prod-head">Panel Discussions</h3>
                <p className="prod-head-text">Participation of experts to share their knowledge</p>
            </div>
            <div className='col text-center'>
                <img src={productImg3} alt='product img' className='product-img-3'/>
                <h3 className="prod-head">Startup Days</h3>
                <p className="prod-head-text">Startup showcase for stakeholders</p>
            </div>
            <div className='col text-center'>
                <img src={productImg4} alt='product img' className='product-img-4'/>
                <h3 className="prod-head">Meetups</h3>
                <p className="prod-head-text">Social gathering of like-minded people with specific agenda</p>
            </div>
            <div className='col text-center'>
                <img src={productImg5} alt='product img' className='product-img-5'/>
                <h3 className="prod-head">Hackathons</h3>
                <p className="prod-head-text">End-to-End Hackathon Management</p>
            </div>
        </div>
    </div>
  )
}
export default Products;
