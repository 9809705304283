import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "./Events.css";
// Import images
import gallaryImg1 from "../../assets/images/events-page/gallery/image-1.jpg";
import gallaryImg2 from "../../assets/images/events-page/gallery/image-2.jpg";
import gallaryImg3 from "../../assets/images/events-page/gallery/image-3.jpg";
import gallaryImg4 from "../../assets/images/events-page/gallery/image-4.jpg";
import gallaryImg5 from "../../assets/images/events-page/gallery/image-5.jpg";
import gallaryImg6 from "../../assets/images/events-page/gallery/image-6.jpg";
import gallaryImg7 from "../../assets/images/events-page/gallery/image-7.jpg";
import gallaryImg8 from "../../assets/images/events-page/gallery/image-8.jpg";
import gallaryImg9 from "../../assets/images/events-page/gallery/image-9.jpg";
import gallaryImg10 from "../../assets/images/events-page/gallery/image-10.jpg";
import Heading from "../../commoncomponents/Heading/Heading";

const images = [
  gallaryImg1,
  gallaryImg2,
  gallaryImg3,
  gallaryImg4,
  gallaryImg5,
  gallaryImg6,
  gallaryImg7,
  gallaryImg8,
  gallaryImg9,
  gallaryImg10,
];

export default function PastEvent() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  return (
    <div className="container py-5">
      <Heading title="Past Events" />
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        loop={true}
        spaceBetween={10}
        navigation={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
        }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper2"
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <img src={img} alt={`slide ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        onSwiper={setThumbsSwiper}
        loop={true}
        spaceBetween={10}
        slidesPerView={4}
        freeMode={true}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className="mySwiper"
      >
        {images.map((img, index) => (
          <SwiperSlide key={index}>
            <img src={img} alt={`thumb ${index + 1}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
