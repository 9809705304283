import React, {useState} from 'react'
import { toast } from 'react-toastify'; 

const LandExpandForm = ({ show, onClose }) => {
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_number: '',
        organization: '',
        linkedIn_link: '',
        company_website: '',
        country_name_to_expand: '',
        rationale_behind_expansion: '',
      });
  if (!show) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/landexpansion`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Form submitted successfully!");
        onClose();
      } else {
        alert('Error submitting form. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting form. Please try again.');
    }
  };
  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <form className="modal-body" onSubmit={handleSubmit}>
          <h3>Land & Expand with Ideas to Impacts Hub</h3>
          <p>Seamlessly transition from a local player to a global contender as we guide you through the intricacies of scaling your business. Leverage our vast network, resources, and expertise to navigate unfamiliar territories and establish a strong foothold. With Ideas to Impacts Hub, take a leap towards international entrepreneurism!</p>
          <div className="mb-3">
            <label className="form-label">Name <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email <span className='star'>*</span></label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="name@example.com"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Contact Number <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              placeholder="Enter contact number"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Organization <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
              placeholder="Organization Name"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">LinkedIn Link <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="linkedIn_link"
              value={formData.linkedIn_link}
              onChange={handleChange}
              placeholder="LinkedIn Profile Link"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Company Website <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="company_website"
              value={formData.company_website}
              onChange={handleChange}
              placeholder="Company Website"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Country You Wish to Expand to<span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="country_name_to_expand"
              value={formData.country_name_to_expand}
              onChange={handleChange}
              placeholder="Country Name"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Your Rationale Behind the Expansion <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="rationale_behind_expansion"
              value={formData.rationale_behind_expansion}
              onChange={handleChange}
              placeholder="Rationale Behind Expansion"
              required
            />
          </div>
          <hr />
          <div className='mb-3'>
            <button className='btn btn-primary' type='submit'>Submit</button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default LandExpandForm;