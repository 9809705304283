import React, { useState } from 'react';
import { toast } from 'react-toastify';

const HostEventform = ({ show, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    organization: '',
    company_brief: '',
    event_type_meet: false,
    event_type_mixer: false,
    event_type_workshop: false,
    event_type_paneldiscussion: false,
    event_type_anyofabove: false,
  });

  if (!show) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/eventhost`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success('Form submitted successfully!');
        onClose();
      } else {
        alert('Error submitting form. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <form className="modal-body" onSubmit={handleSubmit}>
          <h3>Host an Event with Ideas to Impacts Hub</h3>
          <p>Ideas to Impacts Hub aims to build the startup ecosystem and expose multiple avenues to help them grow holistically.</p>
          <p>We are a firm believer in fostering the growth of the startup ecosystem and providing opportunities for comprehensive development. Join us to host and collaborate in organizing events at our venue to become an integral part of the ecosystem.</p>
          <div className="mb-3">
            <label className="form-label">Name <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email <span className='star'>*</span></label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="name@example.com"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Contact Number <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              placeholder="Enter contact number"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Organization <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
              placeholder="Organization Name"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Company Brief <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="company_brief"
              value={formData.company_brief}
              onChange={handleChange}
              placeholder="Company Brief"
              required
            />
          </div>
          <div className='mt-3'>
            <label className="form-label">Type of event you want to host<span className='star'>*</span></label>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="event_type_meet"
                checked={formData.event_type_meet}
                onChange={handleChange}
                id="flexCheckMeet"
              />
              <label className="form-check-label" htmlFor="flexCheckMeet">
                Event type meet
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="event_type_mixer"
                checked={formData.event_type_mixer}
                onChange={handleChange}
                id="flexCheckMixer"
              />
              <label className="form-check-label" htmlFor="flexCheckMixer">
                Event type mixer
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="event_type_workshop"
                checked={formData.event_type_workshop}
                onChange={handleChange}
                id="flexCheckWorkshop"
              />
              <label className="form-check-label" htmlFor="flexCheckWorkshop">
                Event type workshop
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="event_type_paneldiscussion"
                checked={formData.event_type_paneldiscussion}
                onChange={handleChange}
                id="flexCheckPanelDiscussion"
              />
              <label className="form-check-label" htmlFor="flexCheckPanelDiscussion">
                Event type panel discussion
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="event_type_anyofabove"
                checked={formData.event_type_anyofabove}
                onChange={handleChange}
                id="flexCheckAnyOfAbove"
              />
              <label className="form-check-label" htmlFor="flexCheckAnyOfAbove">
                Event type any of above
              </label>
            </div>
          </div>
          <hr />
          <div className='mb-3'>
            <button className='btn btn-primary' type='submit'>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HostEventform;
