import React, { useState } from 'react';
import { toast } from 'react-toastify';

const SpeakerForm = ({ show, onClose }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_number: '',
        organization: '',
        designation: '',
        motivation_behind_ses: '',
    });

    if (!show) {
        return null;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apiBaseUrl}/speaker`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                toast.success('Form submitted successfully!');
                console.log(result);
                onClose();
            } else {
                alert('Error submitting form. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting form. Please try again.');
        }
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>&times;</button>
                <form className="modal-body" onSubmit={handleSubmit}>
                    <h3>Speaker Contact</h3>
                    <p>Ideas to Impacts Hub has become not only an audience favourite rink but also the first choice for many prominent leaders of the industry. Our speakers find it expedient to reach out to bright masses that we bring with the influence in the startup ecosystem. The platform has been fortunate to host a bountiful number of inspirational pioneers who very generously have been sharing their wisdom with all the enthusiasts.</p>
                    <p>Don't hoard on your experiential knowledge- share it with the curious minds at Ideas to Impacts Hub Be the voice of change & motivate curious minds at ideas to impacts hub.</p>

                    <div className="mb-3">
                        <label className="form-label">Name <span className='star'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email <span className='star'>*</span></label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="name@example.com"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Contact Number <span className='star'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            placeholder="Enter contact number"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Organization <span className='star'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="organization"
                            value={formData.organization}
                            onChange={handleChange}
                            placeholder="Organization Name"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Designation <span className='star'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            placeholder="Designation"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Your Motivation behind Helping Startup Ecosystem <span className='star'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="motivation_behind_ses"
                            value={formData.motivation_behind_ses}
                            onChange={handleChange}
                            placeholder="Sharing knowledge and experience with the community."
                            required
                        />
                    </div>

                    <hr />
                    <div className='mb-3'>
                        <button className='btn btn-primary' type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default SpeakerForm;
