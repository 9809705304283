import React, { useEffect, useState } from 'react';
import DashboardNav from './DashboardNav';
import { GetInTouch } from './Adminform/GetInTouch';
import Incubation from './Adminform/Incubation';
import EnterpriseGarden from './Adminform/EnterpriseGarden';
import FranchiseOffer from './Adminform/FranchiseOffer';
import CFOServices from './Adminform/CFOServices';
import Reachoutus from './Adminform/Reachoutus';
import BookSpace from './Adminform/BookSpace';
import BeCenter from './Adminform/BeCenter';
import VirtualAddress from './Adminform/VirtualAddress';
import Hostanevent from './Adminform/Hoastanevent';
import Growbrand from './Adminform/Growbrand';
import Speaker from './Adminform/Speaker';
import LandExpand from './Adminform/LandExpand';

const ViewOtherRegistration = () => {
  const [showAllBtn, setShowAllBtn] = useState(true);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [getInTouchData, setGetInTouchData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);

  const getInData = async () => {
    const result = await fetch(`${apiBaseUrl}/getintouch`);
    const finalResult = await result.json();
    setGetInTouchData(finalResult.data);
  }
  
  useEffect(() => {
    getInData();
  }, []);

  const renderTabContent = () => {
    switch(activeTab) {
      case 1:
        return <><GetInTouch getInTouchData={getInTouchData}/></>;
      case 2:
        return <div><Incubation /></div>;
      case 3:
        return <div><EnterpriseGarden /></div>;
      case 4:
        return <div><FranchiseOffer /></div>;
      case 5:
        return <div><CFOServices /></div>;
      case 6:
        return <div><Reachoutus /></div>;
      case 7:
        return <div><BookSpace /></div>;
      case 8:
        return <div><BeCenter /></div>;
      case 9:
        return <div><VirtualAddress /></div>;
      case 10:
        return <div><Hostanevent /></div>;
      case 11:
        return <div><Growbrand /></div>;
      case 12:
        return <div><Speaker /></div>;
      case 13:
        return <div><LandExpand /></div>;
      default:
        return null;
    }
  }

  return (
    <>
      <DashboardNav showAllEvent={showAllBtn} />
      <div className='container-fluid py-5'>
        <h2>All Registration on Website</h2>
        <div className="tabs2 pt-5">
          <button className={`tab ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>Get In Touch</button>
          <button className={`tab ${activeTab === 2 ? 'active' : ''}`} onClick={() => setActiveTab(2)}>Incubation</button>
          <button className={`tab ${activeTab === 3 ? 'active' : ''}`} onClick={() => setActiveTab(3)}>Enterprise Garden</button>
          <button className={`tab ${activeTab === 4 ? 'active' : ''}`} onClick={() => setActiveTab(4)}>Membership</button>
          <button className={`tab ${activeTab === 5 ? 'active' : ''}`} onClick={() => setActiveTab(5)}>CFO Services</button>
          <button className={`tab ${activeTab === 6 ? 'active' : ''}`} onClick={() => setActiveTab(6)}>Reach out Us</button>
          <button className={`tab ${activeTab === 7 ? 'active' : ''}`} onClick={() => setActiveTab(7)}>Book Space</button>
          <button className={`tab ${activeTab === 8 ? 'active' : ''}`} onClick={() => setActiveTab(8)}>Be a Center</button>
          <button className={`tab ${activeTab === 9 ? 'active' : ''}`} onClick={() => setActiveTab(9)}>Virtual Address</button>
          <button className={`tab ${activeTab === 10 ? 'active' : ''}`} onClick={() => setActiveTab(10)}>Host an Event</button>
          <button className={`tab ${activeTab === 11 ? 'active' : ''}`} onClick={() => setActiveTab(11)}>Grow Brand</button>
          <button className={`tab ${activeTab === 12 ? 'active' : ''}`} onClick={() => setActiveTab(12)}>Speakers</button>
          <button className={`tab ${activeTab === 13 ? 'active' : ''}`} onClick={() => setActiveTab(13)}>Land Expand</button>
        </div>
        <div className="tab-content">
          {renderTabContent()}
        </div>
      </div>
    </>
  )
}

export default ViewOtherRegistration;
