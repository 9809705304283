import React from 'react';
import UnderlineImg from '../../assets/images/Underline.png';

const Heading = ({ title }) => {
  return (
    <div style={{ textAlign: 'center', position: 'relative', }}>
       <h1 style={{ fontFamily: '"Inter Tight", sans-serif', fontWeight:'bold' }}>{title}</h1>
      <img 
        src={UnderlineImg} 
        alt="Underline" 
        style={{ 
          display: 'block', 
          margin: '0 auto',
          marginTop: '-10px',  // Adjust this value to control the space between h1 and the underline image
          width: '15%',        // Adjust this value to control the width of the underline image
          height: 'auto'       // Maintain aspect ratio by setting height to auto
        }} 
      />
    </div>
  );
};

export default Heading;
