import React, {useState, useEffect} from 'react'

const Incubation = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [data,setData] = useState([]);

  const getData = async () => {
    const result = await fetch(`${apiBaseUrl}/incubation`);
    const finalResult = await result.json();
    setData(finalResult.data);
  }
  useEffect(() => {
    getData();
  }, []); 
  return (
    <div className='container-fluid py-5'>
    <div className='table-responsive'>
       <table className='table table-bordered'>
         <thead>
           <tr>
             <th>Name</th>
             <th>Email</th>
             <th>Phone Number</th>
             <th>Organization</th>
             <th>Company Website</th>
             <th>About Company</th>
             <th>Stage of Startup</th>
             <th>Product Deck</th>
           </tr>
         </thead>
         <tbody>
           {data && data.map((data, index) => (
             <tr key={index}>
               <td>{data.name}</td>
               <td>{data.email}</td>
               <td>{data.phone_number}</td>
               <td>{data.organization}</td>
               <td>{data.company_website}</td>
               <td>{data.about_company}</td>
               <td>{data.stage_of_startup}</td>
              <td>{data.product_deck}</td>
             </tr>
           ))}
         </tbody>
       </table>
     </div>
</div>
  )
}
export default Incubation;