import React from 'react'

export const GetInTouch = ({getInTouchData}) => {
  return (
    <div className='container-fluid py-5'>
         <div className='table-responsive'>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Segment of Interest</th>
                  <th>Query</th>
                  <th>Organization</th>
                </tr>
              </thead>
              <tbody>
                {getInTouchData && getInTouchData.map((data, index) => (
                  <tr key={index}>
                    <td>{data.name}</td>
                    <td>{data.email}</td>
                    <td>{data.phone_number}</td>
                    <td>{data.segment_of_intrest}</td>
                    <td>{data.query}</td>
                    <td>{data.organization}</td>
                  
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
    </div>
  )
}
