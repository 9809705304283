import React, {useState} from 'react'
import ArrowBtn from '../../commoncomponents/buttons/ArrowBtn'
import servicesImg1 from '../../assets/images/corporate/image-3.svg'
import benifitsImg2 from '../../assets/images/corporate/benefits/benefits-1.svg'
import benifitsImg3 from '../../assets/images/corporate/benefits/benefits-2.svg'
import benifitsImg4 from '../../assets/images/corporate/benefits/benefits-3.svg'
import benifitsImg5 from '../../assets/images/corporate/benefits/benefits-4.svg'
import benifitsImg6 from '../../assets/images/corporate/benefits/benefits-5.svg'
import benifitsImg7 from '../../assets/images/corporate/benefits/benefits-6.svg'
import CFOServicesform from './CFOServicesform'
const Services = () => {
    const [showModal, setShowModal] = useState(false);
 

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
    return (
        <div className='container-fluid services-container'>
            <div className='container py-5'>
                <div className='row py-5'>
                    <div className='col-md-6'>
                        <div>
                            <h2 className='Services-heading'>CFO Services</h2>
                            <p className='mt-5 Services-text'>Virtual CFO services are a cost-effective alternative to hiring a full-time CFO, providing customized financial advice and support for small and medium-sized businesses.</p>
                            <p className='Services-text'>This enables them to focus on their core competencies while managing finances, developing growth strategies, ensuring compliance with regulations, and receiving financial insights and analysis.</p>
                            <div className='mt-5'>
                            <button
                             onClick={handleOpenModal}
                                style={{
                                    fontFamily: '"Inter Tight", sans-serif',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#F59E0B',
                                    border: 'none',
                                    borderRadius: '9999px',
                                    fontWeight: 'bold',
                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                    width: '160px'
                                }}
                            >Apply Now
                                <span style={{ fontSize: '30px' }}>  &#8594;</span>
                            </button>
                            </div>
                        </div>
                    </div>
                    <CFOServicesform show={showModal}  onClose={handleCloseModal} />
                    <div className='col-md-6'>
                        <img src={servicesImg1} alt='services img' className='img-fluid' />
                    </div>
                </div>
            </div>
            <div className='container pb-5'>
                <h1 className='Services-heading'>Our Benefits</h1>
                <div className='row row-cols-1 row-cols-md-3 g-5 py-5'>
                    <div className="col">
                        <img src={benifitsImg2}  />
                        <h4 className='Services-heading mt-3'>Budget Control</h4>
                    </div>
                    <div className="col">
                        <img src={benifitsImg3}  />
                        <h4 className='Services-heading mt-3'>Increased Cash Flow</h4>
                    </div>
                    <div className="col">
                        <img src={benifitsImg4}  />
                        <h4 className='Services-heading mt-3' >A Vast Network</h4>
                    </div>
                    <div className="col">
                        <img src={benifitsImg5}  />
                        <h4 className='Services-heading mt-3'>Domain Expertise</h4>
                    </div>
                    <div className="col">
                        <img src={benifitsImg6}  />
                        <h4 className='Services-heading mt-3'>Cost Optimisation</h4>
                    </div>
                    <div className="col">
                        <img src={benifitsImg7}  />
                        <h4 className='Services-heading mt-3'>Operational Efficiency</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Services;