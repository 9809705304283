import React from 'react';
import { useLocation } from 'react-router-dom';
import RoutesConfig from './routes';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <>
      <Main />
    </>
  );
};

const Main = () => {
  const location = useLocation();
  const path = location.pathname;

  // Determine if Navbar and Footer should be hidden
  const hideNavbarAndFooter = 
    path === '/admin' || 
    path === '/dashboard' || 
    path === '/addevent' || 
    path === '/ViewOtherRegistration' || 
    path === '/settings' || // Add your new static path here
    /^\/edit-event\/\d+$/.test(path); // Regex for /edit-event/:id

  return (
    <div className="App">
      {!hideNavbarAndFooter && <Navbar />}
      <RoutesConfig />
      {!hideNavbarAndFooter && <Footer />}
      <ToastContainer />
    </div>
  );
};

export default App;
