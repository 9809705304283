import React from 'react'
import Heading from '../../commoncomponents/Heading/Heading';
import CountUp from 'react-countup';
import './Home.css'
const successstories = () => {
  return (
   
    <div className='container-fluid homeStories'>
    <div className='container'>
      <Heading title="We are Proud to Announce the Success Stories" />
      <div className='row g-4 py-5'>
        <div className='col-md-4'>
          <div className='sucess-card'>
            <h3 className='count-text'>10,000 +</h3>
            <p className='count-text-2'>Scouted Startups</p>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='sucess-card'>
          <h3 className='count-text'>
            $ <CountUp end={55} />M
          </h3>
            <p className='count-text-2'>Fund Raised</p>
          </div>
        </div>

        <div className='col-md-4'>
          <div className='sucess-card'>
          <h3 className='count-text'>
            <CountUp end={15} />+
          </h3>
            <p className='count-text-2'>Countries</p>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='sucess-card'>
          <h3 className='count-text'>
            <CountUp end={25} />+
          </h3>
            <p className='count-text-2'>Portfolios</p>
          </div>
        </div>
        <div className='col-md-6'>
          <div className='sucess-card'>
          <h3 className='count-text'>
            <CountUp end={12} />+
          </h3>
            <p className='count-text-2'>Corporate Clients</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
export default successstories;