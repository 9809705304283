import React, {useState, useEffect} from 'react'

const EnterpriseGarden = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [myData,setMyData] = useState([]);

  const getData = async () => {
    const result = await fetch(`${apiBaseUrl}/enterprisegarden`);
    const finalResult = await result.json();
    setMyData(finalResult.data);
  }
  useEffect(() => {
    getData();
  }, []); 
  return (
    <div className='container-fluid py-5'>
    <div className='table-responsive'>
       <table className='table table-bordered'>
         <thead>
           <tr>
             <th>Name</th>
             <th>Email</th>
             <th>Phone Number</th>
             <th>Organization</th>
             <th>Type of Company Startup</th>
             <th>Type of Company Enterprise</th>
             <th>Company Details</th>
             <th>Company Website</th>
             <th>Comapy Deck</th>
           </tr>
         </thead>
         <tbody>
           {myData && myData.map((data, index) => (
             <tr key={index}>
               <td>{data.name}</td>
               <td>{data.email}</td>
               <td>{data.phone_number}</td>
               <td>{data.organization}</td>
               <td>{data.type_of_company_startup ? 'Yes' : 'No'}</td>
               <td>{data.type_of_company_enterprise ? 'Yes' : 'No'}</td>
               <td>{data.company_details}</td>
               <td>{data.company_website}</td>
              <td>{data.company_deck}</td>
             </tr>
           ))}
         </tbody>
       </table>
     </div>
</div>
  )
}
export default EnterpriseGarden;