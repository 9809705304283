import React, { useState } from 'react';
import { toast } from 'react-toastify';

const GardenForm = ({ show, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    organization: '',
    company_details: '',
    company_website: '',
    type_of_company_startup: false,
    type_of_company_enterprise: false,
    company_deck: null
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else if (type === 'file') {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    try {
      const response = await fetch(`${apiBaseUrl}/enterprisegarden`, {
        method: 'POST',
        body: data,
      });

      if (response.ok) {
        const result = await response.json();
        toast.success('Form submitted successfully!');
        onClose();
      } else {
        const errorResult = await response.json();
        toast.error('Error submitting form: ' + errorResult.message);
      }
    } catch (error) {
      toast.error('Error submitting form. Please try again.');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <div className="modal-body">
          <h3>Enterprise Garden</h3>
          <p>For SAP Solutions</p><hr />
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Name <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email <span className='star'>*</span></label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="name@example.com"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Contact Number <span className='star'>*</span></label>
              <input
                type="number"
                className="form-control"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Enter contact number"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Organization <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                placeholder="Organization Name"
                required
              />
            </div>
            <div className='mb-3'>
              <label className="form-label">Type of Company<span className='star'>*</span></label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="type_of_company_startup"
                  checked={formData.type_of_company_startup}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="type_of_company_startup">Startup</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="type_of_company_enterprise"
                  checked={formData.type_of_company_enterprise}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="type_of_company_enterprise">Enterprise</label>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Company Details<span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="company_details"
                value={formData.company_details}
                onChange={handleChange}
                placeholder="Your Answer"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Company Website<span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="company_website"
                value={formData.company_website}
                onChange={handleChange}
                placeholder="Your Answer"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label" htmlFor="formFile">Company Deck </label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                name="company_deck"
                onChange={handleChange}
              />
            </div>
            <hr />
            <div className='mb-3'>
              <button className='btn btn-primary' type='submit'>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default GardenForm;
