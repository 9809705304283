import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Navbar.css';
import mainLogo from '../../assets/images/main-logo.svg'
import GetInTouch from '../../commoncomponents/buttons/GetInTouch';
const Navbar = () => {
  const location = useLocation();
  
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top">
      <div className="container">
        <img src={mainLogo} className='img-logo' alt='main logo'/>
        <button 
          className="navbar-toggler" 
          type="button" 
          data-bs-toggle="collapse" 
          data-bs-target="#navbarSupportedContent" 
          aria-controls="navbarSupportedContent" 
          aria-expanded="false" 
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/startup' ? 'active' : ''}`} to="/startup">Startup</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/corporate' ? 'active' : ''}`} to="/corporate">Corporate</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/facility-space' ? 'active' : ''}`} to="/facility-space">Facility & Space</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/events' ? 'active' : ''}`} to="/events">Events</Link>
            </li>
            <li className="nav-item">
              <Link className={`nav-link ${location.pathname === '/land-expand' ? 'active' : ''}`} to="/land-expand">Land & Expand</Link>
            </li>
          </ul>
          <div>
            <GetInTouch title="Get In Touch"/>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
