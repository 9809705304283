import React, { useState } from 'react';
import { toast } from 'react-toastify';

const VirtualAddform = ({ show, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    organization: '',
    reason_for_virtual_add: '',
  });

  if (!show) {
    return null;
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${apiBaseUrl}/virtualaddress`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        toast.success('Form submitted successfully!');
        console.log(result);
        onClose();
      } else {
        alert('Error submitting form. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error submitting form. Please try again.');
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <form className="modal-body" onSubmit={handleSubmit}>
          <h3>Virtual Address with Ideas to Impacts Hub</h3>
          <p>Ideas to impacts hub offers virtual address so you can establish a professional business presence without the constraints of a physical location. Seamlessly connect with clients, investors, and partners from anywhere, while enjoying the benefits of a prestigious address. Join our community of forward-thinking entrepreneurs and leverage the flexibility and convenience of a virtual workspace to propel your business to new heights. Experience the future of work with our cutting-edge virtual incubator.</p>
          <hr />
          <div className="mb-3">
            <label className="form-label">Name <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email <span className='star'>*</span></label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="name@example.com"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Contact Number <span className='star'>*</span></label>
            <input
              type="number"
              className="form-control"
              name="phone_number"
              value={formData.phone_number}
              onChange={handleChange}
              placeholder="Enter contact number"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Organization <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="organization"
              value={formData.organization}
              onChange={handleChange}
              placeholder="Organization Name"
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Reason to opt for virtual address <span className='star'>*</span></label>
            <input
              type="text"
              className="form-control"
              name="reason_for_virtual_add"
              value={formData.reason_for_virtual_add}
              onChange={handleChange}
              placeholder="Your answer"
              required
            />
          </div>
          <hr />
          <div className='mb-3'>
            <button className='btn btn-primary' type='submit'>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default VirtualAddform;
