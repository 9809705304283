import React, { useState } from 'react';
import { toast } from 'react-toastify';

const StartupForm = ({ show, onClose }) => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone_number: '',
    organization: '',
    stage_of_startup: '',
    product_deck: null,
    company_website: '',
    about_company: ''
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({
        ...formData,
        [name]: files[0]
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('phone_number', formData.phone_number);
    data.append('organization', formData.organization);
    data.append('stage_of_startup', formData.stage_of_startup);
    data.append('product_deck', formData.product_deck);
    data.append('company_website', formData.company_website);
    data.append('about_company', formData.about_company);

    try {
      const response = await fetch(`${apiBaseUrl}/incubation`, {
        method: 'POST',
        body: data
      });
      const result = await response.json();
      if (result.result) {
        toast.success('Form submitted successfully!');
        onClose();
      } else {
        toast.error('Error submitting form: ' + result.message);
      }
    } catch (error) {
      toast.error('Error submitting form. Please try again.');
    }
  };

  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <h3>Incubation with Ideas to Impacts Hub</h3>
            <p>Ideas to Impacts Hub's incubation program fosters the growth of startups, guiding them to the next level. We assist startups in securing funding, gaining access to the market, and creating the ideal environment for their overall development. Additionally, they receive valuable mentorship from industry experts.</p>
            <hr />
            <div className="mb-3">
              <label className="form-label">Name <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Email <span className='star'>*</span></label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="name@example.com"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Contact Number <span className='star'>*</span></label>
              <input
                type="number"
                className="form-control"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                placeholder="Enter contact number"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Organization <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="organization"
                value={formData.organization}
                onChange={handleChange}
                placeholder="Organization Name"
                required
              />
            </div>
            <div className='mb-3'>
              <label className="form-label">Stage of your Startup <span className='star'>*</span></label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="stage_of_startup"
                  value="Ideation"
                  checked={formData.stage_of_startup === 'Ideation'}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Ideation
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="stage_of_startup"
                  value="Validation/ MVP"
                  checked={formData.stage_of_startup === 'Validation/ MVP'}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Validation/ MVP
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="stage_of_startup"
                  value="Early Traction"
                  checked={formData.stage_of_startup === 'Early Traction'}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Early Traction
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="stage_of_startup"
                  value="Scaling/ Expansion"
                  checked={formData.stage_of_startup === 'Scaling/ Expansion'}
                  onChange={handleChange}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  Scaling/ Expansion
                </label>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="formFile" className="form-label">Upload Your Product Deck <span className='star'>*</span></label>
              <input
                className="form-control"
                type="file"
                name="product_deck"
                id="formFile"
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Company Website <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="company_website"
                value={formData.company_website}
                onChange={handleChange}
                placeholder="Enter your company website"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">About Company <span className='star'>*</span></label>
              <input
                type="text"
                className="form-control"
                name="about_company"
                value={formData.about_company}
                onChange={handleChange}
                placeholder="Your Answer"
                required
              />
            </div>
            <hr />
            <div className='mb-3'>
              <button className='btn btn-primary' type='submit'>Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StartupForm;
