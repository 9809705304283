import React from 'react'
import FacilitySpace from '../components/FacilitySpace/FacilitySpace';

 const FacilitySpacePage = () => {
  return (
    <>
      <FacilitySpace />
    </>
  )
}
export default FacilitySpacePage;