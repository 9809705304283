import React, {useState} from "react";
import "./LandExpand.css";
import landCircleImg from "../../assets/images/land-expand/circle.png";
import landgroupOne from "../../assets/images/land-expand/group-1.png";
import landgroupTwo from "../../assets/images/land-expand/group-2.png";
import landMainImg from "../../assets/images/land-expand/main.png";
import checkImg from "../../assets/images/check.png";
import LandExpandForm from "./LandExpandForm";

export const ideas = [
  "Legalities",
  "GTM",
  "Resources and Space",
  "Government Assistance",
  "VCs Network",
  "Incubators Connects",
];

export const regions = [
  "Croatia",
  "Norway",
  "Canada",
  "United Kingdom",
  "Singapore",
];
const LandExpand = () => {
  const [showModal, setShowModal] = useState(false);
 

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-container">
          <div class="row">
            <div className="col-md-6 col-12 first-col">
              <h1 className="land-expand-heading text-start text-center mt-5">
                Take a flight to scale <br />
                your business overseas
              </h1>
            </div>
            <div className="col-md-6 col-12 second-col">
              <img
                src={landCircleImg}
                className="land-img-circle"
                alt="land_expand"
              />
              <img
                src={landgroupOne}
                className="land-group1-img"
                alt="land_expand_one"
              />
              <img
                src={landgroupTwo}
                className="land-group2-img"
                alt="land_expand_two"
              />
              <img
                src={landMainImg}
                className="land-main-img"
                alt="land_expand_main"
              />
            </div>
          </div>
        </div>
      </section>
      {/* Hero Section End*/}

      {/* About Section */}
      <section className="about-section">
        <div className="container about-container">
          <div className="row">
            <div className="col-md-12 col-12 about-text-one">
              <p>
                Embark on a transformative journey with our dynamic "Land and
                Expand" program. Designed to fuel your startup's growth, this
                initiative provides a strategic roadmap for expansion into new
                markets.
              </p>
            </div>
            <div className="col-md-12 col-12 about-text-two">
              <p>
                Seamlessly transition from a local player to a global contender
                as we guide you through the intricacies of scaling your
                business. Leverage our vast network, resources, and expertise to
                navigate unfamiliar territories and establish a strong foothold.
                With Ideas to Impacts Hub, take a leap towards international
                entrepreneurism!
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* About Section */}

      {/* Ideas Section */}
      <section className="ideas-section">
        <div className="container-fluid bg-red">
          <div className="row justify-content-center mb-5">
            <h2 className="col-12 ideas-heading text-center mb-1">
              Land & Expand with Ideas to Impacts Hub
            </h2>
            {ideas.map((idea, index) => (
              <div
                key={index}
                className="col-md-4 col-12 d-flex align-items-center mb-4 ideas-column"
              >
                <img src={checkImg} alt="check" className="img-check me-2" />
                <p className="fs-4 mb-0 lh-1 idea-name">{idea}</p>
              </div>
            ))}
          </div>
          <div className="row px-5 justify-content-center">
            <h1 className="col-12 text-center mb-5 ideas-subhead">
              In expansive regions of
            </h1>
            {regions.map((region, index) => (
              <div key={index} className="card region-card mx-3 p-2">
                <div className="card-body text-center">
                  <h4 className="card-title fw-bolder">{region}</h4>
                </div>
              </div>
            ))}
            <div className="row">
              <div className="col-12 d-flex justify-content-center py-5">
              <button
                             onClick={handleOpenModal}
                                style={{
                                    fontFamily: '"Inter Tight", sans-serif',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#F59E0B',
                                    border: 'none',
                                    borderRadius: '9999px',
                                    fontWeight: 'bold',
                                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                                    width: '160px'
                                }}
                            >Apply Now
                                <span style={{ fontSize: '30px' }}>  &#8594;</span>
                            </button>
              </div>
            </div>
          </div>
        </div>
        <LandExpandForm  show={showModal}  onClose={handleCloseModal}/>
      </section>
      {/* Ideas Section End */}
    </>
  );
};
export default LandExpand;
