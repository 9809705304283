import React from "react";
import ArrowBtn from "../../commoncomponents/buttons/ArrowBtn";

import partnerImg1 from "../../assets/images/home/newpartners/aim (1).png";
import partnerImg2 from "../../assets/images/home/newpartners/atlassian (2).png";
import partnerImg3 from "../../assets/images/home/newpartners/Beckn2.png";
import partnerImg4 from "../../assets/images/home/newpartners/f6s-logo.png";
import partnerImg5 from "../../assets/images/home/newpartners/Headstart.png";
import partnerImg6 from "../../assets/images/home/newpartners/IFSCA LOGO 31_07_2020 FINAL (1).png";
import partnerImg7 from "../../assets/images/home/newpartners/london & partneres.png";
import partnerImg8 from "../../assets/images/home/newpartners/ONDC.png";
import partnerImg9 from "../../assets/images/home/newpartners/pnp (1).png";
import partnerImg10 from "../../assets/images/home/newpartners/seap.jpg.png";
import partnerImg11 from "../../assets/images/home/newpartners/sunbird.png";
import partnerImg12 from "../../assets/images/home/newpartners/T-Hub_Logo-PNG.png";
import partnerImg13 from "../../assets/images/home/newpartners/tekdi.png";
import partnerImg14 from "../../assets/images/home/newpartners/PImage-1.6863adeb.svg";
import partnerImg15 from "../../assets/images/home/newpartners/PImage-2.79485e91.svg";
import partnerImg16 from "../../assets/images/home/newpartners/PImage-3.55174a3b.svg";
import partnerImg17 from "../../assets/images/home/newpartners/PImage-4.15e77716.svg";
import partnerImg18 from "../../assets/images/home/newpartners/PImage-5.5207d0ae.svg";
import partnerImg19 from "../../assets/images/home/newpartners/PImage-6.de9251fb.svg";
import partnerImg20 from "../../assets/images/home/newpartners/PImage-7.94fc4a84.svg";
import partnerImg21 from "../../assets/images/home/newpartners/PImage-8.2f9d166a.svg";
import partnerImg22 from "../../assets/images/home/newpartners/PImage-9.54822fee.svg";
import partnerImg23 from "../../assets/images/home/newpartners/PImage-10.f32c2b22.svg";
import partnerImg24 from "../../assets/images/home/newpartners/PImage-11.64af0aeb.svg";
import partnerImg25 from "../../assets/images/home/newpartners/PImage-12.f70b8647.svg";
import partnerImg26 from "../../assets/images/home/newpartners/indiadc.png";
import partnerImg27 from "../../assets/images/home/newpartners/PImage-14.2b26b3a8.svg";
import partnerImg28 from "../../assets/images/home/newpartners/PImage-15.6d37843d.svg";
import partnerImg29 from "../../assets/images/home/newpartners/d2dinsider.png";

const images = [
  partnerImg1,
  partnerImg26,
  partnerImg3,
  partnerImg8,

  partnerImg2,
  partnerImg6,
  partnerImg11,
  partnerImg13,

  partnerImg9,
  partnerImg10,
  partnerImg19,
  partnerImg16,

  partnerImg12,
  partnerImg14,
  partnerImg20,
  partnerImg21,

  partnerImg29,
  partnerImg22,
  partnerImg23,
  partnerImg27,

  partnerImg28,
  partnerImg24,
  partnerImg15,
  partnerImg17,

  partnerImg4,
  partnerImg5,

  partnerImg7,

  partnerImg18,
];
const Partners = () => {
  return (
    <>
      <div className="container-fluid Partners-container">
        <div className="container">
          <div className="text-center">
            <h1 className="Partners-heding">Partners</h1>
            <h1 className="Partners-heding">
              Helping hands and brains of our ingenious startups
            </h1>
            <div className="row py-5">
              {images.map((image, index) => (
                <div className="col-6 col-sm-4 col-md-3 mb-4" key={index}>
                  <div className="img-container-partner1">
                    <img
                      src={image}
                      className="img-fluid img-member-all"
                      alt={`Partner ${index + 1}`}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-center align-items-center mt-5">
              <ArrowBtn title="Know more" path="/events" />
            </div>
          </div>
        </div>
      </div>
      {/* <div className='container-fluid getStarted'>
            <h1 className='Partners-heding'>Ready to get started? Contact us now !</h1>
            <GetInTouch title="Reach out to us"/>

    </div> */}
    </>
  );
};
export default Partners;
