import React from 'react';
 import {ShowEvent} from './ShowEvent.js'
import 'react-toastify/dist/ReactToastify.css';
import './Admin.css'
import DashboardNav from './DashboardNav.js';

export const Dashboard = () => {
  return (
    <>
     <DashboardNav />
      <ShowEvent  />
    </>
  );
};
