import React from 'react'
import programIcon1 from '../../assets/images/home/section4/icon-1.png'
import programIcon2 from '../../assets/images/home/section4/icon-2.png'
import programIcon3 from '../../assets/images/home/section4/icon-3.png'
import programIcon4 from '../../assets/images/home/section4/icon-4.png'
import './Home.css'
const currentprograms = () => {
    return (
        <div className='container-fluid currentprograms-container'>
            <div className='container py-5'>
                <h1 className='cureent-text'>Current <span className='progrem-text'>Programs</span></h1>
                <h5 className='cureent-text-1'>Assembling the building blocks to a dynamic future with our current programs</h5>

                <div className='row row-cols-1 row-cols-md-4 g-4 pt-5'>
                    <div className='col'>
                        <div className='program-card'>
                            <img src={programIcon1} alt='program-icom' className='program-icon1' />
                            <h5 className='card-heading'>Startup India Seed Fund Scheme</h5>
                            <p className='program-text'>Get financial assistance for your early-stage startup.</p>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='program-card'>
                        <img src={programIcon2} alt='program-icom' className='program-icon2' />

                            <h5 className='card-heading'>Startup Scouting</h5>
                            <p className='program-text'>Join our startup scouting program and make a difference in the tech world.</p>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='program-card'>
                        <img src={programIcon3} alt='program-icom' className='program-icon3' />

                            <h5 className='card-heading'>Enterprise Garden</h5>
                            <p className='program-text'>Ideas to Impacts Hub and Primus Techsystems partnered to bring you the best SAP Solutions</p>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='program-card'>
                        <img src={programIcon4} alt='program-icom' className='program-icon4' />

                            <h5 className='card-heading'>Land & Expand</h5>
                            <p className='program-text'>Take a flight to uncharted regions and grow your business oversees</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default currentprograms;