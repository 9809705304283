import React from 'react'
import StartupForm from '../../components/Startup/StartupForm';
import { Link } from 'react-router-dom';
const ArrowBtn = ({ title, path  }) => {
 
  return (
    <>
      <Link to={path} style={{ textDecoration: 'none' }}> 
      <button
     
      style={{
        fontFamily: '"Inter Tight", sans-serif',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F59E0B',
        border: 'none',
        borderRadius: '9999px',
        fontWeight: 'bold',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        width: '160px'
      }}
    >{title}
      <span style={{ fontSize: '30px' }}>  &#8594;</span>
    </button>
    </Link>
    </>
  )
}
export default ArrowBtn;