// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bi-linkedin{
    color: #0077b5;
    font-size: 25px;
}
.bi-instagram{
    color: #E1306C;
    font-size: 25px;

}
.bi-whatsapp{
    color: #25D366;
    font-size: 25px;

}
.bi-youtube{
    color: #FF0000;
    font-size: 30px;
}
.footer-text{
  font-family: "Inter Tight", sans-serif;

}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,cAAc;IACd,eAAe;;AAEnB;AACA;IACI,cAAc;IACd,eAAe;;AAEnB;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;EACE,sCAAsC;;AAExC","sourcesContent":[".bi-linkedin{\r\n    color: #0077b5;\r\n    font-size: 25px;\r\n}\r\n.bi-instagram{\r\n    color: #E1306C;\r\n    font-size: 25px;\r\n\r\n}\r\n.bi-whatsapp{\r\n    color: #25D366;\r\n    font-size: 25px;\r\n\r\n}\r\n.bi-youtube{\r\n    color: #FF0000;\r\n    font-size: 30px;\r\n}\r\n.footer-text{\r\n  font-family: \"Inter Tight\", sans-serif;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
