import React, {useState, useEffect} from 'react'

const Hostanevent = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [myData,setMyData] = useState([]);

  const getData = async () => {
    const result = await fetch(`${apiBaseUrl}/eventhost`);
    const finalResult = await result.json();
    setMyData(finalResult.data);
  }
  useEffect(() => {
    getData();
  }, []); 
  return (
    <div className='container-fluid py-5'>
    <div className='table-responsive'>
       <table className='table table-bordered'>
         <thead>
           <tr>
             <th>Name</th>
             <th>Email</th>
             <th>Phone Number</th>
             <th>Organization</th>
             <th>Company Brief</th>
             <th>Event type meet</th>
             <th>Event type mixer</th>
             <th>Event type workshop</th>
             <th>Event type panel discussion</th>
             <th>Event type any of above</th>
           </tr>
         </thead>
         <tbody>
           {myData && myData.map((data, index) => (
             <tr key={index}>
               <td>{data.name}</td>
               <td>{data.email}</td>
               <td>{data.phone_number}</td>
               <td>{data.organization}</td>
              <td>{data.company_brief}</td>
              <td>{data.event_type_meet ? "Yes" : "No"}</td>
              <td>{data.event_type_mixer ? "Yes" : "No"}</td>
              <td>{data.event_type_workshop ? "Yes" : "No"}</td>
              <td>{data.event_type_paneldiscussion ? "Yes" : "No"}</td>
              <td>{data.event_type_anyofabove ? "Yes" : "No"}</td>
             
             </tr>
           ))}
         </tbody>
       </table>
     </div>
</div>
  )
}
export default Hostanevent;