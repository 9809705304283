import React, { useState } from 'react';
import speakersImg from '../../assets/images/events-page/image-3.png';
import Heading from '../../commoncomponents/Heading/Heading';
import speakerImg1 from '../../assets/images/events-page/speakers/speaker-1.png';
import speakerImg2 from '../../assets/images/events-page/speakers/speaker-2.jpg';
import speakerImg3 from '../../assets/images/events-page/speakers/speaker-3.jpg';
import speakerImg4 from '../../assets/images/events-page/speakers/speaker-4.jpg';
import speakerImg5 from '../../assets/images/events-page/speakers/speaker-5.jpeg';
import speakerImg6 from '../../assets/images/events-page/speakers/speaker-6.jpeg';
import speakerImg7 from '../../assets/images/events-page/speakers/speaker-7.jpeg';
import speakerImg8 from '../../assets/images/events-page/speakers/speaker-8.jpg';
import speakerImg9 from '../../assets/images/events-page/speakers/speaker-9.jpg';
import speakerImg10 from '../../assets/images/events-page/speakers/speaker-10.jpeg';
import speakerImg11 from '../../assets/images/events-page/speakers/speaker-11.png';
import speakerImg12 from '../../assets/images/events-page/speakers/speaker-12.jpeg';
import speakerImg13 from '../../assets/images/events-page/speakers/speaker-13.jpg';
import speakerImg14 from '../../assets/images/events-page/speakers/speaker-14.jpg';
import speakerImg15 from '../../assets/images/events-page/speakers/speaker-15.jpeg';
import speakerImg16 from '../../assets/images/events-page/speakers/speaker-16.jpg';
import speakerImg17 from '../../assets/images/events-page/speakers/speaker-17.jpeg';
import speakerImg18 from '../../assets/images/events-page/speakers/speaker-18.jpg';
import speakerImg19 from '../../assets/images/events-page/speakers/speaker-19.jpeg';
import speakerImg20 from '../../assets/images/events-page/speakers/speaker-20.jpg';
import speakerImg21 from '../../assets/images/events-page/speakers/speaker-21.jpeg';
import speakerImg22 from '../../assets/images/events-page/speakers/speaker-22.jpg';
import './Events.css'
import SpeakerFOrm from './SpeakerForm';
const speakers = [
  { img: speakerImg1, name: 'Ravi Pandit', designation: 'Chairman & Co-Founder', location: 'KPIT' },
  { img: speakerImg2, name: 'Roby John', designation: 'Founder & CEO', location: 'Super Gaming' },
  { img: speakerImg3, name: 'Amey Mashelkar', designation: 'Head', location: 'Jio Gen Next' },
  { img: speakerImg4, name: 'Mandar Agashe', designation: 'Founder & MD', location: 'Sarvatra Tech' },
  { img: speakerImg5, name: 'Dhwanit Malani', designation: 'CEO', location: 'Bitwise' },
  { img: speakerImg6, name: 'Subodh Dubey', designation: 'Global CIO', location: 'Suzlon' },
  { img: speakerImg7, name: 'Sanket Deodhar', designation: 'VP', location: 'SAP' },
  { img: speakerImg8, name: 'Monish Darda', designation: 'Co-Founder & CTO', location: 'Icertis' },
  { img: speakerImg9, name: 'Sanchit Malik', designation: 'Co-Founder & CEO', location: 'Pazcare' },
  { img: speakerImg10, name: 'Akash Surekha', designation: 'CEO & MD', location: 'JP Morgan' },
  { img: speakerImg11, name: 'Siddharth pai', designation: 'Founding Partner', location: '3One4 Capital' },
  { img: speakerImg12, name: 'Mukta Aphale', designation: 'Founding Member & VP', location: 'Velotio Technologies' },
  { img: speakerImg13, name: 'Kunal Gupta', designation: 'Co-Founder & CEO', location: 'Emotorad' },
  { img: speakerImg14, name: 'Kapil Shelke', designation: 'Co-Founder & CEO', location: 'KPIT' },
  { img: speakerImg15, name: 'Prateek Singhal', designation: 'Co-Founder & COO', location: 'Ecozen Solutions' },
  { img: speakerImg16, name: 'Satyajit Hange', designation: 'Co-Founder', location: 'Two Brothers Organic Farms' },
  { img: speakerImg17, name: 'Sreejith Moolayil', designation: 'Co-Founder & COO', location: 'True Elements' },
  { img: speakerImg18, name: 'Rohit Chattopadhyay', designation: 'Co-Founder', location: 'Fittr' },
  { img: speakerImg19, name: 'Rohan Rehani', designation: 'Co-Founder', location: 'Moonshine Meadery' },
  { img: speakerImg20, name: 'Ajinkya Dhariya', designation: 'Founder & CEO', location: 'Padcare Labs' },
  { img: speakerImg21, name: 'Siddharth Bhalinge', designation: 'Founder & CEO', location: 'Ugaoo' },
  {},
  { img: speakerImg22, name: 'Pallavi Mohadikar', designation: 'Founder & CEO', location: 'Palmonas' },

];

const Speakers = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className='container-fluid'>
        <div className='row no-gutters'>
          <div className='col-md-6 first-div-speaker'>
            <div className='py-5'>
              <h1 className='sp-heading'>Speakers</h1>
              <p className='sp-heading-text'>Ideas to Impacts Hub has become not only an audience favourite rink but also the first choice for many prominent leaders of the industry. Our speakers prefer to reach out to bright masses that we bring with the influence in the startup ecosystem. The platform has been fortunate to host a bountiful number of inspirational pioneers who have been generously  sharing their wisdom with all the enthusiasts.</p>
              <p className='sp-heading-text'>Be the voice of change & motivate curious minds at ideas to impacts hub.</p>
              <div>
                <button
                  onClick={handleOpenModal}
                  style={{
                    fontFamily: '"Inter Tight", sans-serif',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#F59E0B',
                    border: 'none',
                    borderRadius: '9999px',
                    fontWeight: 'bold',
                    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                    width: '160px'
                  }}
                >Apply Now
                  <span style={{ fontSize: '30px' }}>  &#8594;</span>
                </button>
                <SpeakerFOrm show={showModal} onClose={handleCloseModal} />
              </div>
            </div>
          </div>
          <div className='col-md-6 p-0'>
            <img src={speakersImg} alt='speakersImg' className='speakersImg-bg' />
          </div>
        </div>
      </div>
      <div className='container-fluid user-container'>
        <div className='container py-5'>
          <Heading title="Past Speakers" />
          <div className='row row-cols-1 row-cols-md-3 g-4 py-5 justify-content-center'>
            {speakers.map((speaker, index) => {
              // Check if the speaker object is not empty
              if (Object.keys(speaker).length > 0) {
                return (
                  <div className='col' key={index}>
                    <div className='speaker-card'>
                      <img src={speaker.img} alt={speaker.name} className='speaker-img' />
                      <h4 className='speaker-name'>{speaker.name}</h4>
                      <p className='speaker-designation'>{speaker.designation}</p>
                      <p className='speaker-location'>{speaker.location}</p>
                    </div>
                  </div>
                );
              }
              return null; // Skip rendering if the object is empty
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Speakers;
