import React, { useState } from "react";
import MemberShipImg from "../../assets/images/startup/Image-5.svg";
import MemberShipBgImg from "../../assets/images/startup/bg-image-2.svg";
import Heading from "../../commoncomponents/Heading/Heading";
import ArrowBtn from "../../commoncomponents/buttons/ArrowBtn";
import checkImg from "../../assets/images/check.png";
import FranchiseForm from "./FranchiseForm";
export const features = [
  "Net core- 3 months free subscription",
  "COOE- 3 months free subscription (Additional 25% on yearly billing)",
  "AWS Cloud Services- Credits up to $100K USD, for 2 Years",
  "Fresh works- Credits worth $6,000",
  "Zoho- Credits worth $ 2000",
  "Zoom Calls- 20-30% discount on billings",
  "HubSpot- Subscription @ 90% Off",
  "Jet Brains- 6 months free subscriptions & 50% discount on extension",
  "MathWorks- 1 year free subscription",
  "Access to the Global Community of Ideas to Impacts Hub",
  "A vibrant community of Diverse & inspiring peers",
  "Enriching professional and social events",
];
const Membershipprogram = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="container-fluid py-5">
        <Heading title="Membership Program" />
        <div className="row g-0 py-5">
          <div className="col-md-7 bg-image-member">
            <h1 className="member-heading">
              Unveil the gateway to your <br />
              startup's every need with <br />
              Ideas to Impacts Hub
            </h1>
          </div>
          <div className="col-md-5">
            <img src={MemberShipImg} alt="member-img" className="img-member" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div>
              <h2 className="offer-heading">Our Offerings</h2>
              <ul className="offer-list">
                <li>
                  Support with local & international GTM and Channel
                  Partnership.
                </li>
                <li>Scale and set up a business in international markets.</li>
                <li>
                  Application support in funding and grants through various
                  schemes - TIDE, Startup Seed Fund, etc.
                </li>
                <li>Avail our business partnerships & visa programs</li>
                <li>
                  Be part of our networking tours, trade shows, meet-up events
                </li>
                <li>
                  Avail heavy discounts and membership benefits on many start-up
                  focused products via our partnership with Amazon Web Services,
                  Intuit, Hubspot, Zoom and much more!
                </li>
              </ul>
            </div>
            <div className="py-5">
              <button
                onClick={handleOpenModal}
                style={{
                  fontFamily: '"Inter Tight", sans-serif',
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#F59E0B",
                  border: "none",
                  borderRadius: "9999px",
                  fontWeight: "bold",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  width: "160px",
                }}
              >
                Apply Now
                <span style={{ fontSize: "30px" }}> &#8594;</span>
              </button>
            </div>
            <FranchiseForm show={showModal} onClose={handleCloseModal} />
          </div>
          <div className="col-md-6">
            <h2 className="offer-heading-2">
              Some Highlights of our Discount Incubation Perk
            </h2>
            {features.map((feature, index) => (
              <div className="d-flex ">
                <img src={checkImg} alt="check" className="img-check" />
                <p key={index} className="ms-2 feature-list feature-list-text">
                  {feature}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Membershipprogram;
