import React, { useState } from 'react';
import { toast } from 'react-toastify';

const Growbrandform = ({ show, onClose }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone_number: '',
        organization: '',
        company_brief: '',
        preference_event_type: '',
    });

    if (!show) {
        return null;
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleRadioChange = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            preference_event_type: e.target.value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${apiBaseUrl}/growbrand`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                toast.success('Form submitted successfully!');
                console.log(result);
                onClose();
            } else {
                alert('Error submitting form. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting form. Please try again.');
        }
    };

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>&times;</button>
                <form className="modal-body" onSubmit={handleSubmit}>
                    <h3>Brand Visibility at I2I Hub Events </h3>
                    <p>The experimental world of tech startups is anything but still and we make sure to maintain the acceleration by organising encouraging opportunities to network with like-minded people.</p>
                    <p>If you wish to be exposed to such bright pool of audience to create brand awareness or organise specifically curated events</p>
                    <div className="mb-3">
                        <label className="form-label">Name <span className='star'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Email <span className='star'>*</span></label>
                        <input
                            type="email"
                            className="form-control"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="name@example.com"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Contact Number <span className='star'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            placeholder="Enter contact number"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Organization <span className='star'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="organization"
                            value={formData.organization}
                            onChange={handleChange}
                            placeholder="Organization Name"
                            required
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Company Brief <span className='star'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="company_brief"
                            value={formData.company_brief}
                            onChange={handleChange}
                            placeholder="Company Brief"
                            required
                        />
                    </div>
                    <div className='mt-3'>
                        <label className="form-label">Preference in types of events<span className='star'>*</span></label>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preference_event_type"
                                value="Meetups"
                                checked={formData.preference_event_type === 'Meetups'}
                                onChange={handleRadioChange}
                            />
                            <label className="form-check-label">
                                Meetups
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preference_event_type"
                                value="Mixers"
                                checked={formData.preference_event_type === 'Mixers'}
                                onChange={handleRadioChange}
                            />
                            <label className="form-check-label">
                                Mixers
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preference_event_type"
                                value="Workshops"
                                checked={formData.preference_event_type === 'Workshops'}
                                onChange={handleRadioChange}
                            />
                            <label className="form-check-label">
                                Workshops
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preference_event_type"
                                value="Panel Discussions"
                                checked={formData.preference_event_type === 'Panel Discussions'}
                                onChange={handleRadioChange}
                            />
                            <label className="form-check-label">
                                Panel Discussions
                            </label>
                        </div>
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="preference_event_type"
                                value="Any of the above/ Open to discussion"
                                checked={formData.preference_event_type === 'Any of the above/ Open to discussion'}
                                onChange={handleRadioChange}
                            />
                            <label className="form-check-label">
                                Any of the above/ Open to discussion
                            </label>
                        </div>
                    </div>
                    <hr />
                    <div className='mb-3'>
                        <button className='btn btn-primary' type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Growbrandform;
