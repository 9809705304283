import React from 'react'
import { Corporate } from '../components/Corporate/Corporate';

const CorporatePage = () => {
  return (
   <>
    <Corporate />
   </>
  )
}
export default CorporatePage;