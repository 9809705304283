import React from 'react'
import ArrowBtn from '../../commoncomponents/buttons/ArrowBtn';
import SpeakersImg1 from '../../assets/images/home/speakers/speaker-1.png'
import SpeakersImg3 from '../../assets/images/home/speakers/speaker-3.jpg'
import SpeakersImg4 from '../../assets/images/home/speakers/speaker-4.jpg'
import SpeakersImg5 from '../../assets/images/home/speakers/speaker-5.jpeg'
import speakerImg22 from '../../assets/images/events-page/speakers/speaker-22.jpg';

const Speakers = () => {
  return (
    <div className='container speaker-container'>
        <div className='row'>
            <div className='col-md-5'>
                <h1 className='speakers-text-heading'>Great Speakers</h1>
                <p className='speakers-text'>Ideas to Impacts Hub has become not only an audience favourite rink but also the first choice for many prominent leaders of the industry.</p>
            
                <div className='mt-5'>
                <h1 className='speakers-text-heading'>Be a Speaker</h1>
                <p className='speakers-text'>Don't hoard on your experiential knowledge- share it with the curious minds at Ideas to Impacts Hub</p>
                </div>
                <div className='py-5'>
                    <ArrowBtn title="Know more" path="/events"/>
                </div>
            </div>
            <div className='col-md-7'>
                <div className='row g-3'>
                    <div className='col-md-4'>
                        <div className='speakers-card'>
                            <img src={SpeakersImg1}  alt='speakers-img' className='speacker-img'/>
                         <p className='speakers-name'>Ravi Pandit <br />KPIT</p>
                        
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='speakers-card'>
                            <img src={speakerImg22}  alt='speakers-img' className='speacker-img'/>
                            <p className='speakers-name'>Pallavi Mohadikar <br /> Founder & CEO Palmonas</p>
                        </div>
                    </div>
                    <div className='col-md-4'></div>
                    <div className='col-md-4'>
                        <div className='speakers-card'>
                            <img src={SpeakersImg3}  alt='speakers-img' className='speacker-img'/>
                         <p className='speakers-name'>Amey Mashelka <br/> Jio Gen Next</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='speakers-card'>
                            <img src={SpeakersImg4}  alt='speakers-img' className='speacker-img'/>
                         <p className='speakers-name'>Mandar Agashe <br />Sarvatra Tech</p>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='speakers-card'>
                            <img src={SpeakersImg5}  alt='speakers-img' className='speacker-img'/>
                         <p className='speakers-name'>Dhwanit Malani <br />Bitwise</p>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Speakers;
