import React from 'react';
import Heading from '../../commoncomponents/Heading/Heading';
import pastImg1 from '../../assets/images/corporate/record/record-1.svg';
import pastImg2 from '../../assets/images/corporate/record/record-2.svg';
import pastImg3 from '../../assets/images/corporate/record/record-3.svg';
import pastImg4 from '../../assets/images/corporate/record/record-4.svg';
import pastImg5 from '../../assets/images/corporate/record/record-5.svg';
import pastImg6 from '../../assets/images/corporate/record/record-6.svg';
import pastImg7 from '../../assets/images/corporate/record/record-7.svg';
import './Corporate.css';

const pastCardData = [
  {
    img: pastImg1,
    heading: 'NTT Data',
    text: 'Ideas to Impacts Hub partnered with TIE Pune for the NTT Data TIPP Program and scouted start-ups from APAC and Europe.',
  },
  {
    img: pastImg2,
    heading: 'AWS',
    text: 'Ideas to Impacts Hub hosted a highly beneficial program for AWS to curate specific start-ups for building use cases for AWS Marketplace.',
  },
  {
    img: pastImg3,
    heading: 'Microsoft',
    text: 'Microsoft India partnered with I2I Hub to scout companies in India with the objective of developing joint GTM with enterprise start-ups.',
  },
  {
    img: pastImg4,
    heading: 'Icertis',
    text: 'Ideas to Impacts Hub partnered to help Icertis for the Largest AI-ML Blockchain Hackathon in India to attract high quality talent.',
  },
  {
    img: pastImg5,
    heading: 'FIS',
    text: 'FIS Global partnered with I2I Hub to scout startups in the fintech space across APAC region for the program InnovateIN48 Partner Edition.',
  },
  {
    img: pastImg6,
    heading: 'Bridgestone',
    text: 'Bridgestone partnered with I2I Hub for scouting startups in the mobility space as per their requirement.',
  },
  {
    img: pastImg7,
    heading: 'Toronto Business Development Center',
    text: 'TBDC partnered with I2I Hub to scout Indian tech startups who wish to expand their business in Canada under the Land & Expand program.',
  },
];

const PastRecords = () => {
  return (
    <div className='container py-5'>
      <Heading title="Past Records" />
      <div className="row row-cols-1 row-cols-md-3 g-5 py-5 justify-content-center">
        {pastCardData.map((card, index) => (
          <div className="col" key={index}>
            <div className='past-card'>
              <img src={card.img} alt='past img' className='img-past' />
              <div className='past-body'>
                <h2 className='past-heading'>{card.heading}</h2>
                <p className='past-text'>{card.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PastRecords;
