import React, {useState, useEffect} from 'react'

const FranchiseOffer = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [myData,setMyData] = useState([]);

  const getData = async () => {
    const result = await fetch(`${apiBaseUrl}/franchisemember`);
    const finalResult = await result.json();
    setMyData(finalResult.data);
  }
  useEffect(() => {
    getData();
  }, []); 
  return (
    <div className='container-fluid py-5'>
    <div className='table-responsive'>
       <table className='table table-bordered'>
         <thead>
           <tr>
             <th>Name</th>
             <th>Email</th>
             <th>Phone Number</th>
             <th>Organization</th>
             <th>GTM & Channel Partnership</th>
             <th>Land and Expansion with I2I</th>
             <th>Discounted Products/ Tools</th>
             <th>Grants & Fundings</th>
             <th>Mentorship</th>
             <th>More details</th>
           </tr>
         </thead>
         <tbody>
           {myData && myData.map((data, index) => (
             <tr key={index}>
               <td>{data.name}</td>
               <td>{data.email}</td>
               <td>{data.phone_number}</td>
               <td>{data.organization}</td>
               <td>{data.lookfor_gtm ? 'Yes' : 'No'}</td>
               <td>{data.lookfor_landexp ? 'Yes' : 'No'}</td>
               <td>{data.loofor_discprotool ? 'Yes' : 'No'}</td>
               <td>{data.lookfor_grantfund ? 'Yes' : 'No'}</td>
              <td>{data.lookfor_mentor ? 'Yes' : 'No'}</td>
              <td>{data.more_details}</td>
             </tr>
           ))}
         </tbody>
       </table>
     </div>
</div>
  )
}
export default FranchiseOffer;