import React from 'react'
import { Startup } from '../components/Startup/Startup';

 const StartupPage = () => {
  return (
    <>
      <Startup />
    </>
  )
}
export default StartupPage;